<template>
  <VTooltip>
    <iconify-icon :icon="iconName" :class="iconClass" inline />
    <template #content>
      <div class="tw-max-w-xs tw-text-sm">
        {{ description }}
      </div>
    </template>
  </VTooltip>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    description: {
      type: String,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
    },
  },
})
</script>
