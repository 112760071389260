<template>
  <Tooltip :disabled="disabled">
    <slot></slot>
    <template #popper>
      <slot name="content">
        <div class="tw-text-sm tw-max-w-xs">
          {{ content }}
        </div>
      </slot>
    </template>
  </Tooltip>
</template>

<script lang="ts">
import { Tooltip } from 'floating-vue'

export default defineComponent({
  components: { Tooltip },
  props: {
    content: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
